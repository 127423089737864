
export const Init = async()=> {
    
    if (!window.keplr) {
        alert("Please install keplr extension");
    } else {
        const chainId = "Neutaro-1";
        await window.keplr.enable(chainId);
        const offlineSigner = window.keplr.getOfflineSigner(chainId);
        const accounts = await offlineSigner.getAccounts();
        return accounts;
    }
}