import { IUnsignedCommand, Pact, createClient, createEckoWalletQuicksign, isSignedTransaction } from '@kadena/client';

export const NumberOfTokens = async(kdaAddress, ClientAddress, ChainName) =>{
  const client = createClient(
    ClientAddress,
  );
   const GetAmountOfTokens = Pact.builder
   //k:57577f5ea2c7ff0ca7e85f1337b87ac78a03b37dcbc04fc190be99aa0bae2966
   //k:048fb834bf7d93a173120258a5dff263f0e43eaffd347751815b2c1daec00b06
   //'+kdaAddress+'
   .execution('(namespace "free")(free.timpi-kda-to-cosmos.get-allocation-with-kda "'+kdaAddress+'")')
   // set chain id and sender
   .setMeta({
     chainId: '1',
     senderAccount: kdaAddress,
     gasLimit: 100000,
     gasPrice: 0.000001
   })
   .addSigner(kdaAddress.substring(2))
   // set networkId
   .setNetworkId(ChainName)
   // create transaction with hash
   .createTransaction();
   const signedCommand = await createEckoWalletQuicksign()(GetAmountOfTokens);

   const response = await client.local(signedCommand);//await client.submit(SignedCmd);
   return response;
}

export const WriteToSmartcontract = async(kdaAddress, keplrAddress, ClientAddress, ChainName) =>{
  const client = createClient(
    ClientAddress,
  );
   const WriteToSmUnsignedCMD = Pact.builder
   .execution('(namespace "free")(free.timpi-kda-to-cosmos.write-keplr-address "'+keplrAddress+'" "'+ kdaAddress+'")')
   // set chain id and sender
   .setMeta({
     chainId: '1',
     senderAccount: kdaAddress,
     gasLimit: 100000,
     gasPrice: 0.000001
   })
   .addSigner(kdaAddress.substring(2))

   .setNetworkId(ChainName)
   // create transaction with hash
   .createTransaction();
   const signedCommand = await createEckoWalletQuicksign()(WriteToSmUnsignedCMD);
   const response = await client.local(signedCommand);//await client.submit(SignedCmd);
   if(response.result.status != 'failure'){
     const finalResponse = await client.submit(signedCommand);//await client.submit(SignedCmd);
     return finalResponse;
   }
   else{
    throw (response);
   }
}

