import './App.css';
import logo from './logo_small.png';
import React, { Component } from 'react';
import {Init} from './ConnectKeplr.js'
import {NumberOfTokens} from './KadenaTransaction.ts'
import {WriteToSmartcontract} from './KadenaTransaction.ts'
import {KDAInit} from './EckoWalletCalls.js'

class App extends Component {
  keplrWalletaddress = '';
  eckoWalletAddress = '';
  numberOfTokens = 0.0;
  ChainName = "mainnet01";//
  //ClientAddress = 'https://api.testnet.chainweb.com/chainweb/0.0/testnet04/chain/1/pact';
  ClientAddress = 'https://api.chainweb.com/chainweb/0.0/mainnet01/chain/1/pact'
  state = {
    KdaValue: '',
  };
  state = {
    KeplrValue: '',
  };
  state = {
    TokenValue: '',
  };
  state = {
    TransactionDone: '',
  };
  connectKeplr = async () => {
    const Accounts = await Init();
    try{
      this.keplrWalletaddress= Accounts[0].address;
      console.log(this.keplrWalletaddress);
      this.setState({
        KeplrValue: this.keplrWalletaddress,
      });
    }catch(error){
      console.log('An error has occured: '+error);
      alert('An error has occured: '+error);
    }
  };

  connectEcko = async () => {
    const Accounts = await KDAInit(this.ChainName);
    try{
    this.eckoWalletAddress = Accounts.wallet.account;
      console.log(this.eckoWalletAddress);
      this.setState({
        KdaValue: this.eckoWalletAddress,
      });
    }catch (error){
      if(error.message.includes("Cannot read properties of undefined (reading 'wallet')")){
        alert("Please make sure that you have choosen Mainnet in your eckoWallet app.");
      }
      else{
        console.log('An error has occured: '+error);
        alert('An error has occured: '+error);
      }
    }
  };

  NumberOfToken = async () => {
    if(this.eckoWalletAddress.startsWith('k:') && this.keplrWalletaddress.startsWith('neutaro')){
      try{
        const SMReturn = await NumberOfTokens(this.eckoWalletAddress, this.ClientAddress,this.ChainName);
        this.numberOfTokens = (SMReturn.result.data[0].allocation*2)*1.1825;
        console.log(this.numberOfTokens);
        this.setState({
          TokenValue: this.numberOfTokens,
        });
      } catch(error){
        if(error.message.includes("Cannot read properties of undefined (reading 'allocation')")){
          alert("Your wallet is not part of the list from KDLaunch. Please make sure you are connected with the right wallet. If you don't have access to this wallet anymore, please get in touch with KDLaunch.");
        }
        else if (error.message.includes('read: row not found:')){
          alert("No KDA found. You need to have KDA in your wallet for the gas fee. Please make sure you have some KDA in your wallet.");

        }
        else{
          console.log('An error has occured: '+error);
          alert('An error has occured: '+error);
        }
      }
    }
    else{
      alert('Please connect both wallets first, and make sure you have the right wallet addresses. For Keplr, it needs to start with neutaro. For eckoWallet it needs to start with k:');
    }
  };
  WriteToContract = async () => {
    if(this.eckoWalletAddress.startsWith('k:') && this.keplrWalletaddress.startsWith('neutaro') && this.numberOfTokens != 0){
      try{
        const Accounts = await WriteToSmartcontract(this.eckoWalletAddress, this.keplrWalletaddress, this.ClientAddress, this.ChainName);
        //var eckoAddress = Accounts.wallet.account;
        console.log(Accounts);
        this.setState({
          TransactionDone: 'Congratulations, the claim registration was successful. You can close this window now',
        });
        }catch(error){
          console.log('An error has occured: '+error);
          if(error.result.error.message.includes("Insert: row found for key")){
          alert("This Neutaro wallet has already been used to claim. Please use a different Neutaro wallet.");
          }
          else{
            alert('An error has occured: '+error.result.error.message);
          }
        }
    }
    //"{"tag":"PactInternalError","contents":"checkInsertIsOK: Insert: row found for key neutaro1l3tz3hgk0zzanpg4cr65splu5sz9juym6e6q0w"}"
    else{
      alert('Please finish all previous steps first, and make sure you have the right wallet addresses. For Keplr, it needs to start with neutaro. For eckoWallet it needs to start with k:');
    }
  };

  handleChangeKDA = (event) => {
    this.setState({
      value: event.target.KdaValue,
    });
  };
  handleChangeKeplr = (event) => {
    this.setState({
      value: event.target.KeplrValue,
    });
  };
  handleChangeNumberTokens = (event) => {
    this.setState({
      value: event.target.TokenValue,
    });
  };
  handleChangeTransactionDone = (event) => {
    this.setState({
      value: event.target.TransactionDone,
    });
  };
  render() {
    return (
        <><header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p style={{color:'black', fontVariant: 'small-caps'}}>
          Timpi - KDLaunch IDO Claim Registration Portal
        </p>
        <p style={{marginBottom: '35px', color:'black',fontSize:'16px', fontVariant: 'regular'}}>Register your claim to receive your allocation that will be distributed 24 hours before token launch</p>
        <div style={{width: "600px", backgroundColor: '#e4ecec', marginBottom: '50px'}}>
        <div className="accordion" id="accordionExample">
          <div className="accordion-item" style={{backgroundColor: '#ffffff', color: '#000000'}}>
            <h2 className="accordion-header" id="headingOne">
              <button style={{color: 'black',backgroundColor: '#e4ecec'}} className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Keplr Wallet information
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div style={{fontSize: '1rem', padding:'10px'}}> 
              <p>From browser extension to mobile and web applications, Keplr provides powerful and secure tools that best suit for your on‒chain activities.</p><p>To install Keplr please go here: <a href="https://www.keplr.app/download" target="_blank">keplr.app</a> or here for the chrome extension: <a href="https://chromewebstore.google.com/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?hl=en" target="_blank">chrome web store</a></p>
              <p>Once you have installed the extension and have created your account, please go to here:<br/><b><a href="https://keplr.timpi.io/" target="_blank">Timpi Keplr</a> to create your new Neutaro (Timpi) Keplr wallet by clicking on Add Neutaro Chain to Keplr</b></p>
              <p>Keplr will now ask you if you want to add Neutaro to your wallet. Please confirm this question.</p><p> Once you have done that, you can go back to this page and click on<br/><em>1. Get the Neutaro wallet address (Keplr wallet)</em>.</p> 
              </div>
            </div>
          </div>
          <div className="accordion-item" style={{backgroundColor: '#ffffff', color: '#000000'}}>
            <h2 className="accordion-header" id="headingTwo">
              <button style={{color: 'black',backgroundColor: '#e4ecec'}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              eckoWallet information
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div style={{fontSize: '1rem', padding:'10px'}}>
              <p>eckoWALLET is the first and most used Kadena-native web-extension wallet.</p>
              <br/><span className="center" style={{color: 'red',display: 'flex',flexWrap: 'nowrap',justifyContent: 'center',with: '100%'}}><b>Make sure your are on the latest version of eckoWALLET!!</b></span><br/>
              <p>Robust and user-friendly: eckoWALLET was designed to interact with dapps on the Kadena network, enabling seamless interaction without ever having to leave your browser.</p>
              <p>You can install the web extension here: <a href="https://chromewebstore.google.com/detail/eckowallet/bofddndhbegljegmpmnlbhcejofmjgbn?pli=1" target="_blank">Chrome Web Store </a>
              If you use a different wallet, you can migrate to eckoWallet.</p>
              <p>Please have a look at this video: <a href="https://www.youtube.com/watch?v=bLcslJtMoJo" target="_blank">eckoWALLET: Import Seed Phrase</a></p>
              <p>For Chainweaver users, you need to have your mnemonic for this wallet as Chainweaver does not support keys.</p>
              <p>To get your KDA wallet address: First please make sure you use the right wallet in the eckoWallet extension.<br/><span className="center" style={{color: 'red',display: 'flex',flexWrap: 'nowrap',justifyContent: 'center',with: '100%'}}><b>You must use the Wallet you used in the KDLaunch Timpi IDO!!</b></span><br/> Click on <em>2. Get the Kadena wallet address (eckoWallet)</em> and your wallet address will appear  in the field below.</p>
              </div>
            </div>
          </div>
          <div className="accordion-item" style={{backgroundColor: '#ffffff', color: '#000000'}}>
            <h2 className="accordion-header" id="headingThree">
              <button style={{color: 'black',backgroundColor: '#e4ecec'}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Why is the token called Neutaro and not Timpi?
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div style={{fontSize: '1rem', padding:'10px'}}>
              <p>Back in July 2023, the Timpi team recognized the need for an independent entity that can drive transformative change responsibly and transparently.</p>
              <p>This realization has given birth to Neutaro, a groundbreaking layer 1 blockchain platform that operates within the powerful Cosmos Layer 0 ecosystem.</p>
              <p>Please read more about this here: <a href="https://medium.com/@timpi.io/neutaro-empowering-a-new-era-of-independent-blockchain-innovation-f9ae8247e2c6" target="_blank">Neutaro: Empowering a New Era of Independent Blockchain Innovation</a></p>
              <p>The token Timpi uses is called Neutaro and has the symbol $NTMPI on exchanges like BitMart.</p>
              </div>
            </div>
          </div>
          <div className="accordion-item" style={{backgroundColor: '#ffffff', color: '#000000'}}>
            <h2 className="accordion-header" id="headingFive">
              <button style={{color: 'black',backgroundColor: '#e4ecec'}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
              Token Calculation
              </button>
            </h2>
            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
              <div style={{fontSize: '1rem', padding:'10px'}}>
              <p>The original calculation at the KDLaunch IDO was:</p>
              <p>1 KDA = 80 Timpi tokens</p>
              <p>Since then, we have doubled the supply and therefore the calculation is now:</p>
              <p>1 KDA = 160 Timpi tokens</p>
              <p>From there, we added an APY of 10% for the time you had to wait.</p>
              <p>Example of someone who bought tokens on the <b>20th of April</b> during the IDO:</p>
              <p>You bought Timpi tokens for 50 KDA:</p>
              <p>50 * 160 = 8,000 tokens </p>
              <p>8,000 + 18.25% APY (21 months) = 9,460 tokens (compounded annually)</p>
              <p><b>Value calculation:</b></p>
              <p>The KDA price at the end of the IDO (20th April 2022) was $5.63
                When you invested 50 KDA, that means you would have invested $281.50</p>
              <p>With the increase in tokens and the APY, you get 9,460 Neutaro tokens at a release price of $0.04.</p>
              <p>9,460 * $0.04 = $378.40</p>
              <p>Your investment was $281.50 and you get $378.40 now in tokens. This means you got a value uplift of 34.42%</p>
              </div>
            </div>
          </div>
          <div className="accordion-item" style={{backgroundColor: '#ffffff', color: '#000000'}}>
            <h2 className="accordion-header" id="headingSix">
              <button style={{color: 'black',backgroundColor: '#e4ecec'}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
              Claim Process
              </button>
            </h2>
            <div id="collapseSix" className='accordion-collapse collapse' aria-labelledby="headingSix" data-bs-parent="#accordionExample">
              <div style={{fontSize: '1rem', padding:'10px'}}>
                <p>1. Click on <em>1. Get the Neutaro wallet address (Keplr wallet)</em> to connect your Keplr wallet to this page. Keplr will ask you to confirm.</p>
                <p>2. Click on <em>2. Get the Kadena wellet address (eckoWallet)</em> to connect your eckoWallet to this page. eckoWallet will ask you to confirm. If you have more wallets, please make sure you choose the right one (see eckoWallet Information)!</p>
                <p>3. Click on <em>3. Show the number of neutaro tokens you'll recieve</em>. This queries a smart contract that has all allocations stored. Please confirm the eckoWallet signature request.</p>
                <p>4. Click on <em>Register your claim</em> to send a transaction to a smart contract that stores your Keplr wallet address with your eckoWallet address. Please confirm the eckoWallet signature requests. The call is to the smart contract which costs gas fees!<br/><b>Please make sure you have enough KDA in the wallet for the gas fees!!</b></p>
                <p><b>That is all, you are ready to receive your tokens on the Token Distribution Event (TDE) which is about 24 hours before the token is released on the exchange</b></p>
              </div>
            </div>
          </div>
        </div>
      </div>
       <div style={{marginBottom:'20px'}}>
          <button className='btn btn-primary' style={{width: "400px", backgroundColor:'#dc1e3a',borderColor:'#dc1e3a'}} onClick={this.connectKeplr}>1. Get the Neutaro wallet address (Keplr wallet)</button>
          <input disabled type="text" className="form-control" id="InputKeplr" aria-describedby="KeplrHelp"
              value={this.state.KeplrValue}
              onChange={this.handleChangeKeplr}></input>
          
        </div>
        <div style={{marginBottom:'20px'}}>
          <button className='btn btn-primary' style={{width: "400px", backgroundColor:'#dc1e3a',borderColor:'#dc1e3a'}} onClick={this.connectEcko}>2. Get the Kadena wallet address (eckoWallet)</button>
          <input disabled type="text" className="form-control" id="InputEcko" aria-describedby="EckoHelp"
              value={this.state.KdaValue}
              onChange={this.handleChangeKDA}></input>
        </div>
        <div style={{marginBottom:'20px'}}>
          <button className='btn btn-primary' style={{width: "400px", backgroundColor:'#dc1e3a',borderColor:'#dc1e3a'}} onClick={this.NumberOfToken}>3. Show the number of neutaro tokens you'll receive</button>          
          <input disabled type="text" className="form-control" id="InputTokens" aria-describedby="TokenHelp" style={{marginBottom:'-20px'}}
              value={this.state.TokenValue}
              onChange={this.handleChangeNumberTokens}></input>
              <span style={{fontSize:'12px',color:'black'}}>*Estimated. The final amount will be calculated at distribution event</span>
        </div>
        <div className='center'>
          <button className='btn btn-warning' style={{color:'white',width: "400px", backgroundColor:'#69727d',borderColor:'#69727d'}} onClick={this.WriteToContract}>Register your claim</button><br/>
          <span style={{width:'100%', color:'#000000', fontSize:'1rem',display:'flex',justifyContent:'center'}} onChange={this.handleChangeTransactionDone}>{this.state.TransactionDone}</span>
        </div>
        <div>
        </div>
      </header>
        </>
    );
  }
}

export default App;
