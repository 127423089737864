const isXWalletInstalled = () => {
    const { kadena } = window;
    return Boolean(kadena && kadena.isKadena);
};

export const KDAInit = async (networkID) => {
    
    if (!isXWalletInstalled()) {
        alert("Please install the eckoWallet extension");
    }
    else {
        const connectToWallet = async () => {
            return window.kadena.request({
                method: 'kda_connect',
                networkId: networkID
            })
        }
        const accountInfo = async () => {
            return window.kadena.request({
                method: 'kda_requestAccount',
                networkId: networkID,
            })
        }
        try {
            const results = await connectToWallet()
            if (results.status === 'success') {
                const account = await accountInfo()
                return account;
            }
        } catch (error) {
            return error;
        }
    }
}